import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { IoRocketOutline } from "react-icons/io5";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi";
import { TbBrandLinktree } from "react-icons/tb";
import { FaMedium } from "react-icons/fa6";

import usdt from "../Images/usdt.svg";
import usdt1 from "../Images/usdt1.png";
import usdc from "../Images/usdc.png";
import eth from "../Images/eth.svg";
import eth1 from "../Images/eth1.png";
import card from "../Images/card.png";
import logorx from "../Images/logorx.svg";

import {
  StyledBtn,
  StyledButton,
  StyledText,
  ToastNotify,
} from "../components/SmallComponents/AppComponents";
import Loading from "../components/SmallComponents/loading";
import { AppContext } from "../utils";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import {
  preSaleReadFunction,
  preSaleWriteFunction,
  tokenReadFunction,
  usdcReadFunction,
  usdcWriteFunction,
  usdtReadFunction,
  usdtWriteFunction,
} from "../ConnectivityAssets/hooks";
import { formatUnits, parseUnits } from "viem";
import { preSaleAddress } from "../ConnectivityAssets/environment";
import { fetchBalance } from "wagmi/actions";
import { ExampleButton } from "../components/SmallComponents/StyledWalletButton";

function formatNumberWithCommas(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function HeroSection() {
  const matches = useMediaQuery("(max-width:950px)");
  const { open } = useWeb3Modal();
  const { account } = useContext(AppContext);
  const [token, setToken] = useState("ETH");
  const [loading, setloading] = useState();
  const [callFunction, setCallFunction] = useState(false);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [userDataArray, setUserDataArray] = useState([]);
  const [isButtonClicked, setButtonClicked] = useState(true);
  const [isButtonClicked1, setButtonClicked1] = useState(false);
  const [isButtonClicked2, setButtonClicked2] = useState(false);
  const handlebgClick = () => {
    // Toggle the state when the button is clicked
    setButtonClicked(!isButtonClicked);
    setButtonClicked1(false);
    setButtonClicked2(false);
  };
  const handlebgClick1 = () => {
    // Toggle the state when the button is clicked

    setButtonClicked1(!isButtonClicked1);
    setButtonClicked(false);
    setButtonClicked2(false);
  };
  const handlebgClick2 = () => {
    // Toggle the state when the button is clicked

    setButtonClicked2(!isButtonClicked2);
    setButtonClicked(false);
    setButtonClicked1(false);
  };
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };
  useEffect(() => {
    (async () => {
      try {
        let totalTokens = await tokenReadFunction("balanceOf", [account]);
        setTokenBalance(+formatUnits(totalTokens.toString(), 18));
        let currentID = await preSaleReadFunction("currentSale");
        let arr = [];
        for (let i = 1; i <= Number(currentID.toString()); i++) {
          const user_Data = await preSaleReadFunction("userData", [
            account,
            i.toString(),
          ]);
          let obj = {
            investedAmount: +formatUnits(user_Data[0].toString(), 6),
            receivedTokens: +formatUnits(user_Data[1].toString(), 18),
          };
          arr.push(obj);
        }
        console.log([...arr], "array");
        setUserDataArray([...arr]);
      } catch (error) {
        console.log(error);
      }
    })();
    setCallFunction(false);
  }, [account, callFunction, token]);

  return (
    <Box id="home">
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Container maxWidth="lg">
        <Grid container spacing={3} py={matches ? 4 : 4} px={!matches && 3}>
          <Grid
            item
            md={12}
            xs={12}
            justifyContent="flex-start"
            alignItems="center"
            display="flex"
            pb={matches && 2}
          >
            <Box>
              {!matches && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <a
                    href="https://x.com/rexas"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaTwitter
                      style={{
                        color: "#F0B90B",
                        fontSize: "25px",
                        marginRight: "23px",
                      }}
                    />
                  </a>
                  <a
                    href="https://t.me//rexas"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaTelegramPlane
                      style={{
                        color: "#F0B90B",
                        fontSize: "28px",
                        marginRight: "23px",
                      }}
                    />
                  </a>
                  <a
                    href=" https://linktr.ee/rexasfinance"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TbBrandLinktree
                      style={{
                        color: "#F0B90B",
                        fontSize: "28px",
                        marginRight: "23px",
                      }}
                    />
                  </a>
                  <a
                    href="https://medium.com/@rexasfinance"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaMedium
                      style={{
                        color: "#F0B90B",
                        fontSize: "28px",
                      }}
                    />
                  </a>
                </Box>
              )}
              <Typography
                mt={1}
                sx={{
                  fontFamily: "accelerator",
                  fontSize: matches ? "28px" : "48px",
                  textAlign: "center",
                }}
              >
                <span
                  style={{
                    background: "transparent",
                    backgroundImage:
                      "linear-gradient(271.68deg, #F0B90B 0%, #E8749E 100%)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Rexas Protocol
                </span>{" "}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: matches ? "28px" : "48px",
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                Tokenize Real World Assets Everything, Everywhere
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  mt={3}
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    textAlign: "center",
                    width: matches ? "100%" : "80%",
                  }}
                >
                  Rexas Protocol is your gateway to the future of asset
                  management. Rexas empowers you to own or tokenize virtually
                  any real-world asset, from real estate and art to commodities
                  and intellectual property, on a global scale. With Rexas, you
                  gain access to a world where asset liquidity and investment
                  opportunities are boundless. Embrace the power of blockchain
                  technology to make investing more inclusive, transparent, and
                  efficient.
                </Typography>
              </Box>

              <Box
                mt={matches ? 4 : 4}
                display="flex"
                justifyContent={"center"}
              >
                <a
                  href="https://rexas.com/rexas-whitepaper.pdf"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <div className="cardBox" style={{ marginRight: "20px" }}>
                    <div className="card-animation">
                      <div className="card-content">
                        <h2
                          style={{
                            fontSize: "16px",
                            fontWeight: 500,
                            fontFamily: "Poppins",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#f0b90b",
                          }}
                        >
                          <IoRocketOutline
                            style={{
                              color: "#F0B90B",
                              fontSize: "25px",
                              marginRight: "5px",
                            }}
                          />{" "}
                          Whitepaper
                        </h2>
                      </div>
                    </div>
                  </div>
                </a>
                <a
                  href="https://rexas.com/audit/"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                  rel="noreferrer"
                >
                  <div
                    className="cardBox"
                    style={{ marginRight: "20px", width: "120px" }}
                  >
                    <div
                      className="card-animation"
                      style={{
                        width: "80%",
                      }}
                    >
                      <div
                        className="card-content"
                        style={{
                          width: "115px",
                        }}
                      >
                        <h2
                          style={{
                            fontSize: "16px",
                            fontWeight: 500,
                            fontFamily: "Poppins",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                          }}
                        >
                          Audit
                        </h2>
                      </div>
                    </div>
                  </div>
                </a>
              </Box>
              <Typography
                mt={3}
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  textDecoration: "underline",
                  textAlign: "center",
                }}
              >
                Audited | 100% Secure & Verified
                {/* <img
                  src={audit}
                  width="100px"
                  style={{ marginLeft: "10px" }}
                  alt=""
                /> */}
              </Typography>
              {matches && (
                <Box
                  mt={2}
                  display="flex"
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <a
                    href="https://x.com/rexas"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaTwitter
                      style={{
                        color: "#F0B90B",
                        fontSize: "25px",
                        marginRight: "23px",
                      }}
                    />
                  </a>
                  <a
                    href="https://t.me//rexas"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaTelegramPlane
                      style={{
                        color: "#F0B90B",
                        fontSize: "28px",
                        marginRight: "23px",
                      }}
                    />
                  </a>
                  <a
                    href=" https://linktr.ee/rexasfinance"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TbBrandLinktree
                      style={{
                        color: "#F0B90B",
                        fontSize: "28px",
                        marginRight: "23px",
                      }}
                    />
                  </a>
                  <a
                    href="https://medium.com/@rexasfinance"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FaMedium
                      style={{
                        color: "#F0B90B",
                        fontSize: "28px",
                      }}
                    />
                  </a>
                </Box>
              )}
            </Box>
          </Grid>
          {/* Presale stage 1 Card data  */}

          <Grid
            item
            md={12}
            xs={12}
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <Box
              sx={{
                background:
                  "radial-gradient(60% 51.57% at 50% 50%, #F0B90B 0%, rgba(232, 116, 158, 0.5) 100%)",
                width: matches ? "100%" : "55%",
                borderRadius: matches ? "20px" : "25px",
                p: matches ? 0.5 : 1,
              }}
            >
              <Box
                pb={5}
                px={!matches && 5}
                sx={{
                  background:
                    "linear-gradient(152.97deg, rgba(65, 66, 71) 0%, rgba(27, 29, 35) 100%)",
                  borderRadius: "18px",
                  textAlign: "center",
                  border: "1px solid #2c2d32",
                }}
              >
                <Typography
                  mt={4}
                  sx={{
                    fontFamily: "accelerator",
                    fontSize: matches ? "28px" : "40px",
                    textAlign: "center",
                  }}
                >
                  <span
                    style={{
                      background: "transparent",
                      backgroundImage:
                        "linear-gradient(271.68deg, #F0B90B 0%, #E8749E 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Rexas
                  </span>{" "}
                </Typography>

                <Typography
                  mt={2}
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: matches ? "16px" : "22px",
                    textAlign: "center",
                    fontWeight: 600,
                  }}
                >
                  Total Tokens User Holds:{" "}
                  <span style={{ color: "yellow" }}>
                    {parseFloat(tokenBalance).toFixed(2)}
                  </span>
                </Typography>
                <Typography
                  mt={2}
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: matches ? "16px" : "22px",
                    textAlign: "center",
                    fontWeight: 600,
                    color: "#F0B90B",
                  }}
                >
                  Purchase History
                </Typography>
                {!account ? (
                  <Box mt={3} width="100%">
                    <ExampleButton width="100%" />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      border: "1px solid #fff",
                      borderRadius: "20px",
                      p: 2,
                      mx: matches && 2,
                      mt: 3,
                    }}
                  >
                    {tokenBalance > 0 ? (
                      <>
                        <Box
                          px={2}
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontSize: matches ? "10px" : "16px",
                              textAlign: "center",
                              fontWeight: 300,
                            }}
                          >
                            Stage
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontSize: matches ? "10px" : "18px",
                              textAlign: "center",
                              fontWeight: 300,
                            }}
                          >
                            Received Tokens
                          </Typography>
                          <Typography
                            sx={{
                              fontFamily: "Poppins",
                              fontSize: matches ? "10px" : "18px",
                              textAlign: "center",
                              fontWeight: 300,
                            }}
                          >
                            Invested Amount
                          </Typography>
                        </Box>
                        {userDataArray.map(
                          ({ receivedTokens, investedAmount }, index) => {
                            return (
                              <Box
                                mt={2}
                                px={4}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <Typography
                                  sx={{
                                    fontFamily: "Poppins",
                                    fontSize: matches ? "10px" : "16px",
                                    textAlign: "center",
                                    fontWeight: 300,
                                  }}
                                >
                                  {index + 1}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontFamily: "Poppins",
                                    fontSize: matches ? "10px" : "18px",
                                    textAlign: "center",
                                    fontWeight: 300,
                                  }}
                                >
                                  {parseFloat(receivedTokens).toFixed(2)}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontFamily: "Poppins",
                                    fontSize: matches ? "10px" : "18px",
                                    textAlign: "center",
                                    fontWeight: 300,
                                  }}
                                >
                                  {parseFloat(investedAmount).toFixed(2)}
                                </Typography>
                              </Box>
                            );
                          }
                        )}
                      </>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "17px",
                          fontWeight: "700",
                          fontFamily: "Poppins",
                          color: "#fff",
                          lineHeight: "28px",
                          textAlign: "center",
                        }}
                      >
                        You have no holdings yet!
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box mt={5}>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: matches ? "18px" : "28px",
              // textAlign: "center",
            }}
          >
            Add Rexas Finance (RXS) In Your Wallet
          </Typography>
          <Typography
            mt={2}
            sx={{
              fontFamily: "Poppins",
              fontSize: matches ? "13px" : "18px",
              lineHeight: matches ? "24px" : "32px",
              // textAlign: "center",
            }}
          >
            To see your RXS tokens in your wallet, add Rexas Finance (RXS) as a
            custom token by using the following details:
            <br />
            Token Name: Rexas Finance
            <br />
            Symbol: RXS
            <br />
            Network/Chain: Ethereum
            <br />
            Decimals: 18
            <br />
            Contract Address: 0x9eAeBd7E73D97E78c77fAB743e6FFA1b550e224c
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default HeroSection;
